import { Card, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import SubMenu from './SubMenu';

export default function SettingsGeneral({ settingFormInfo }) {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
    { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] }
  ]);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí puedes realizar acciones con los datos del formulario, como enviarlos a un servidor
    console.log('Datos del formulario:', { nombre, email, mensaje });
    // También puedes reiniciar los campos del formulario
    setNombre('');
    setEmail('');
    setMensaje('');
  };
  return (
    <>
      <Row>
        <Col xs={5}>
          {/* ESTE COMPONENTE RENDERIZA LA LISTA DE CONFIGURACIONES QUE SE LE PASEN */}
          <SubMenu
            //ESTE ARREGLO PUEDE SER CAMBIADO ENVIANDO DIFERENTES ARREGLOS CON EL TÍTULO Y LA LISTA DE CONFIGURACIONES
            settingsList={listMenu}
          // selectedSetting={selectSetting}
          />
        </Col>
        <Col xs={19} className='pl-2'>
          {/* ESTE COMPONENTE RECIBE LA INFORMACIÓN QUE SERÁ RENDERIZADA, FALTA DEFINIR SI EL FORM SERÁ RENDERIZADO POR MEDIO DE UN OBJETO O POR DIFERENTES COMPONENTES DADO QUE CAMBIAN VARIAS 
              OPCIONES DENTRO DEL DISEÑO */}
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo ? settingFormInfo : null}</h2>
          </Card>
          <Card className='bg-white rounded shadow-none'>
            fyguhij
          </Card>
        </Col>
      </Row>
    </>
    // <form onSubmit={handleSubmit}>
    //   <div>
    //     <label htmlFor="nombre">Nombre: {settingFormInfo ? settingFormInfo : null}</label>
    //     <input
    //       type="text"
    //       id="nombre"
    //       value={nombre}
    //       onChange={(event) => setNombre(event.target.value)}
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="email">Email:</label>
    //     <input
    //       type="email"
    //       id="email"
    //       value={email}
    //       onChange={(event) => setEmail(event.target.value)}
    //     />
    //   </div>
    //   <div>
    //     <label htmlFor="mensaje">Mensaje:</label>
    //     <textarea
    //       id="mensaje"
    //       value={mensaje}
    //       onChange={(event) => setMensaje(event.target.value)}
    //     />
    //   </div>
    //   <button type="submit">Enviar</button>
    // </form>
  )
}
