const routesMap = {
  // public payment routes

  paymentTourSuccessStripe: {
    path: '/payment-tour-success/stripe',
    name: 'Payment Success With Stripe',
    exact: true,
  },
  paymentTourCanceledStripe: {
    path: '/payment-tour-canceled/stripe',
    name: 'Payment Canceled With Stripe',
    exact: true,
  },
  paymentTourFinishedStripe: {
    path: '/payment-tour-finished/stripe',
    name: 'Payment Finished With Stripe',
    exact: true,
  },
  paymentPaypalTourSuccessStripe: {
    path: '/payment-tour-success/paypal',
    name: 'Payment Success With Paypal',
    exact: true,
  },
  paymentPaypalTourCanceledStripe: {
    path: '/payment-tour-canceled/paypal',
    name: 'Payment Canceled With Paypal',
    exact: true,
  },

  // logged users routes

  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
  },
  login: {
    path: '/auth/login',
    name: 'Login',
    exact: true,
  },
  logout: {
    path: '/logout',
    name: 'Logout',
    exact: true,
  },
  terms: {
    path: '/terms',
    name: 'Términos y Condiciones',
    exact: true,
  },
  builder: {
    path: '/builder',
    name: 'Configurar tema',
    exact: true,
  },
  profile: {
    path: '/profile',
    name: 'Perfil',
    exact: true,
  },

  // user routes
  user: {
    path: '/users',
    name: 'Usuarios',
    exact: true,
  },
  account: {
    path: '/account',
    name: 'Cuenta',
    exact: true,
  },
  accountStripeConnectOk: {
    path: '/account/stripe-connect-ok',
    name: 'Conexión con Stripe',
    exact: true,
  },
  accountStripeConnectRequestLink: {
    path: '/account/stripe-connect-request-link',
    name: 'Solicitar nuevo enlace',
    exact: true,
  },
  accountPaypalConnectOk: {
    path: '/account/paypal-connect-ok',
    name: 'Conexión con Paypal',
    exact: true,
  },
  permission: {
    path: '/permissions',
    name: 'Permisos',
    exact: true,
  },
  newPermission: {
    path: '/permissions/new',
    name: 'Nuevo Permiso',
    exact: true,
  },
  editPermission: {
    path: '/permissions/edit/:id',
    name: 'Editar Permiso',
    exact: false,
  },
  business: {
    path: '/business',
    name: 'Negocios',
    exact: true,
  },
  businessTours: {
    path: '/business/tours/:id',
    name: 'Tours de negocio',
    exact: false,
  },
  newBusinessTour: {
    path: '/business/tours/:id/new',
    name: 'Configurar Tour',
    exact: false,
  },
  editBusinessTour: {
    path: '/business/tours/:id/edit/:idt',
    name: 'Configurar Tour',
    exact: false,
  },
  newBusiness: {
    path: '/business/new',
    name: 'Nuevo negocio',
    exact: true,
  },
  editBusiness: {
    path: '/business/edit/:id',
    name: 'Editar negocio',
    exact: false,
  },
  // configBusinessTour: {
  //   path: '/business/config-tour/:id',
  //   name: 'Configurar Tour'
  // },
  configBusinessRestaurant: {
    path: '/business/config-restaurant/:id',
    name: 'Configurar Restaurante',
    exact: false,
  },
  configBusinessParking: {
    path: '/business/config-parking/:id',
    name: 'Configurar Estacionamiento',
    exact: false,
  },

  // catalogs routes
  discountCodes: {
    path: '/discount-codes',
    name: 'Códigos de Descuento',
    exact: true,
  },
  offers: {
    path: '/offers',
    name: 'Ofertas',
    exact: true,
  },
  tourBooking: {
    path: '/tour-booking/:id',
    name: 'Reservas',
    exact: true,
  },
  tourBookingList: {
    path: '/tour-booking-list',
    name: 'Reservas',
    exact: true,
  },
  tourBookingPayment: {
    path: '/tour-booking-payment',
    name: 'Pagos',
    exact: true,
  },
  newTourBooking: {
    path: '/tour-booking/:id/new',
    name: 'Nueva Reserva',
    exact: false,
  },
  rescheduleTourBooking: {
    path: '/tour-booking-list/:id/reschedule/:idtb',
    name: 'Reprogramar Reserva',
    exact: false,
  },
  sellers: {
    path: '/sellers',
    name: 'Vendedores',
    exact: true,
  },
  settings: {
    path: '/settings',
    name: 'SettingsExample',
    exact: true,
  },
  settingsGeneral: {
    path: '/settings/general',
    name: 'SettingsExampleGeneral',
    exact: true,
  },
  settingsReservaciones: {
    path: '/settings/reservaciones',
    name: 'SettingsExampleReservaciones',
    exact: true,
  },
  tourScheduleGeoTrackings: {
    path: '/tour-geo-trackings',
    name: 'Historial rastreo geográfico de Tours',
    exact: true,
  },
  singleTourSchedGeoTracking: {
    path: '/tour-geo-trackings/:id',
    name: 'Detalles de Rastreo geográfico',
    exact: false,
  },
  // Visitas Booking
  visitasBooking: {
    path: '/visitas',
    name: 'Visitas',
    exact: true,
  },
}

export default routesMap
