
import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'antd'
import SubMenu from './SubMenu';

function SettingsReservaciones({ settingFormInfo }) {
  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
    { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] }
  ]);

  return (
    <div>
      <Row>
        <Col xs={5}>
          {/* ESTE COMPONENTE RENDERIZA LA LISTA DE CONFIGURACIONES QUE SE LE PASEN */}
          <SubMenu
            //ESTE ARREGLO PUEDE SER CAMBIADO ENVIANDO DIFERENTES ARREGLOS CON EL TÍTULO Y LA LISTA DE CONFIGURACIONES
            settingsList={listMenu}
          // selectedSetting={selectSetting}
          />
        </Col>
        <Col xs={19} className='pl-2'>
          {/* ESTE COMPONENTE RECIBE LA INFORMACIÓN QUE SERÁ RENDERIZADA, FALTA DEFINIR SI EL FORM SERÁ RENDERIZADO POR MEDIO DE UN OBJETO O POR DIFERENTES COMPONENTES DADO QUE CAMBIAN VARIAS 
              OPCIONES DENTRO DEL DISEÑO */}
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo ? settingFormInfo : null}</h2>
          </Card>
          <Card className='bg-white rounded shadow-none'>
            fyguhijdfgyuiouyut
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SettingsReservaciones;
