
import { Card } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

{/*<div class="menu-item">
  <a href="#" class="menu-link">
    <span class="menu-icon">
      <i class="bi bi-bar-chart fs-3"></i>
    </span>
    
  </a>
</div> */}
export default function SubMenu({ settingsList, selectedSetting }) {
  return (
    <Card className='bg-white rounded shadow-none'>

      <div class="menu-nav menu menu-column menu-title-gray-800 menu-active-bg-primary">
        {
          settingsList.map((section, index) => (
            <>
              <b key={index}>{section.title}</b>
              {
                section.list.map((setting, index) => (
                  <div class="menu-item" >{/*onClick={() => selectedSetting(setting)*/}
                    <NavLink to={`/settings/${setting}`} key={index} class="menu-link" >{setting}</NavLink>
                  </div >
                ))
              }
            </>
          ))
        }
      </div>
    </Card >
  )
}
