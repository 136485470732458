import React from 'react';
import useBookingsToday from './hook';
import { Button, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import InlineLoader from 'src/app/components/InlineLoader';
import { TableWrapperANTD } from 'src/app/components/table';
import { utils } from 'src/utils';

const BookingsToday = ({ className }) => {

  const {
    loading,
    bookings,
    reloadTable
  } = useBookingsToday();

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Reservas de hoy
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Rastreo de las reservas de hoy
          </span>
        </h3>
        <div className="card-toolbar">

        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">

        <Button type='default' onClick={e => reloadTable()} icon={<ReloadOutlined />}>
          Actualizar
        </Button>
        <br />
        <br />

        <TableWrapperANTD>
          <table style={{ tableLayout: "auto" }}>
            <colgroup>
              <col width="15%" />
              <col width="25%" />
              <col width="10%" />
              <col width="20%" />
              <col width="15%" />
            </colgroup>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Ticket</th>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Tour</th>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Horario</th>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Turista</th>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Transporte</th>
                <th className="ant-table-cell" style={{ fontSize: '1.2rem' }}>Status seguimiento</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {
                loading ?
                  <tr className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell py-4 text-center" colSpan={6}>
                      <InlineLoader />
                    </td>
                  </tr>
                  : null
              }

              {
                bookings.length === 0 ?
                  <tr className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell py-4" colSpan={6}>
                      <div className="text-center text-muted">
                        Sin reservas!
                        <br />
                        Al parecer no hay reservas
                      </div>
                    </td>
                  </tr>
                  : null
              }


              {
                bookings.map((bookingObj, index) => (
                  <tr key={index} className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell py-4">
                      {bookingObj.ticket}
                    </td>
                    <td className="ant-table-cell">
                      {bookingObj.Tour ? bookingObj.Tour.name : '-'}
                    </td>
                    <td className="ant-table-cell">
                      {bookingObj.start_time ? utils.formatStringtoTime(bookingObj.start_time) : '-'}
                    </td>
                    <td className="ant-table-cell">
                      {bookingObj.tourist_first_name + ' ' + bookingObj.tourist_last_name} <br />
                      <span className="text-muted font-weight-bold">{bookingObj.tourist_email || bookingObj.tourist_phone_number ? `${bookingObj.tourist_email || ''} | ${bookingObj.tourist_phone_code || ''} ${bookingObj.tourist_phone_number || ''}` : ''}</span>
                    </td>
                    <td className="ant-table-cell">
                      {
                        bookingObj.Transport ? bookingObj.Transport.name : ''
                      }
                    </td>
                    <td className="ant-table-cell">
                      {
                        !bookingObj.transport_id ? <Tag color="orange">Sin transporte</Tag> : ''
                      }

                      {
                        bookingObj.transport_id && bookingObj.reception_status !== 'confirmed' ? <Tag color="default">Pendiente</Tag> : ''
                      }

                      {
                        bookingObj.transport_id && bookingObj.reception_status === 'confirmed' && bookingObj.boarding_status !== 'confirmed' ? <Tag color="blue">Recepcionado</Tag> : ''
                      }

                      {
                        bookingObj.transport_id && bookingObj.boarding_status === 'confirmed' && !bookingObj.has_left_transport ? <Tag color="cyan">A bordo</Tag> : ''
                      }

                      {
                        bookingObj.transport_id && bookingObj.has_left_transport ? <Tag color="green">Finalizado</Tag> : ''
                      }
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </TableWrapperANTD>

      </div>
    </div>
  );
};

export default BookingsToday;