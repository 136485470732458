import React, { useState, useEffect } from "react";
import { Card, Row, Col } from 'antd'
import NearestTourBooking from './NearestTourBooking';
import TopSellers from './TopSellers';
import BookingsToday from './BookingsToday';
import GeoTrackingTransport from "./GeoTrackingTransport";
import { useSelector } from 'react-redux';
import PermissionWrapper from 'src/app/components/utils/permission-wrapper';
import { SessionService } from 'src/services';

export function DashboardPage() {
  const updateMenuTours = useSelector((state) => state.refreshTours)
  const { user } = useSelector((state) => state.auth);

  const [toursList, setTourList] = useState([])

  const getListTours = async () => {
    try {
      let response = await SessionService.getSession()
      if (response.status === 'OK') {
        setTourList(response?.data?.tours || [])
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    getListTours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMenuTours])

  return <div>

    {
      toursList.length > 0 && user.show_all_sections_dashboard ?
        <>
          <Row gutter={[24, 24]} className="mb-3">
            <Col xs={24} sm={24} md={18} lg={18}>
              <NearestTourBooking toursList={toursList} className="card-stretch gutter-b" />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <TopSellers className="card-stretch gutter-b" />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <GeoTrackingTransport toursList={toursList} className="card-stretch gutter-b" />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <BookingsToday toursList={toursList} className="card-stretch gutter-b" />
            </Col>
          </Row>

        </>
        : toursList.length > 0 ?
          <Row gutter={[24, 24]} className="mb-3">
            <Col xs={24} sm={24} md={24} lg={24}>
              <TopSellers className="card-stretch gutter-b" />
            </Col>
          </Row>
          :
          null
    }
    {/* <Card title='Dashboard' >
    </Card> */}
  </div>
}
